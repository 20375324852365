<template>
  <rest-table
      endpoint="albums"
      :filter="filter"
      :fields="fields">

    <template v-slot:cell(artistTitle)="data">
      <router-link :to="{ name: 'release-detail', params: { id: data.item.AlbumID } }">
        <strong>{{ data.item.ProductDetails.Attribution }}</strong> - {{ data.item.ProductDetails.Title }} {{ data.item.ProductDetails.VersionTitle }}
      </router-link>
    </template>

    <template v-slot:cell(ReleaseDate)="data">
      {{ data.item.ReleaseDate|dateFormat }}
    </template>

    <template v-slot:cell(CoverArtAvailable)="data">
      <span v-if="data.item.CoverArtAvailable == '1'" style="font-weight: bold; color: green">V</span>
      <span v-if="data.item.CoverArtAvailable == '0'" style="font-weight: bold; color: red">X</span>
    </template>

    <template v-slot:cell(actions)="data">
      <router-link :to="{ name: 'release-detail', params: { id: data.item.AlbumID } }">
        <b-icon-pencil-square></b-icon-pencil-square>
      </router-link>
    </template>
  </rest-table>
</template>

<script>
  import RestTable from '@/components/RestTable.vue'

  export default {
    name: 'ReleaseList',
    components: {
      RestTable,
    },
    props: {
      filter: Object
    },
    data () {
      return {
        fields: [
          {
            key: 'idx',
            label: '#'
          },
          {
            key: 'artistTitle',
            label: 'Artiest en Titel',
            sortable: true,
          },
          {
            key: 'Label',
            label: 'Label',
            sortable: true,
          },
          {
            key: 'ReleaseDate',
            label: 'Datum',
            sortable: true,
          },
          {
            key: 'TotalTrackCount',
            label: 'Aant.',
            sortable: true,
          },
          {
            key: 'ProductDetails.DeliveryStatus.Description',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'CoverArtAvailable',
            label: 'C',
            headerTitle: 'Cover beschikbaar',
            sortable: true,
          },
          {
            key: 'ProductDetails.ReportingCode',
            label: 'Boep ID',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Acties',
            class: 'link-icons',
          },

        ]
      }
    },
    methods: {

    }
  }
</script>